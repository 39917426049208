<template>
  <div>
    <app-header @getHeight="getHeight"></app-header>
    <div style="background-color: #fff">
      <div class="max_width min_width">
        <div class="flex align_center padtb_20" :style="{ 'margin-top': offsetHeight + 'px' }">
          <div class="f838 size_14">当前位置：</div>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <!-- <el-breadcrumb-item class="d8f">政策法规</el-breadcrumb-item> -->
            <el-breadcrumb-item class="" style="color: #ff6618" v-if="type == 1">"专精特新"中小企业列表</el-breadcrumb-item>
            <el-breadcrumb-item class="" style="color: #ff6618" v-else-if="type == 2">创新型中小企业分布图</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </div>
    </div>

    <div>
      <div class="max_width min_width">
        <section class="bgfff">
          <div style="
                  width: 1518px;
                  padding: 30px;
                  background-color: rgb(255, 221, 204); 
                  border-radius: 5px;
                ">
            <div>
              <div class="flex align_start" style="padding: 10px 0px">
                  <div class="fnf size_15 weight marr_30 text_nowrap">
                    地区：
                  </div>

                
                  
                  <div class="text_line1" style="width: calc(100% - 75px)">
                    <span
                      class="fnf weight size_15 marr_30 laws_font"
                      :class="{ laws_tag: address == '' }"
                      @click="setTag('address')"
                      >全部</span
                    >
                    <span class="fnf size_15 marr_30 laws_font" v-for="(item, index) in options" :key="index"
                      :class="{ laws_tag: address == item.id }" @click="setTag('address', item.id)">{{ item.name }}</span>
                  </div>

      </div>
      
        <div class="flex align_start" style="padding: 10px 0px">
                <div class="fnf size_15 weight marr_30 text_nowrap">
                  批次：
                </div>
                <div class="text_line1" style="width: calc(100% - 75px)">
                  <span class="fnf weight size_15 marr_30 laws_font" :class="{ laws_tag: batch == '' }"
                    @click="setTag('batch')">全部</span>
                  <span class="fnf size_15 marr_30 laws_font" v-for="(item, index) in batcharr" :key="index"
                    :class="{ laws_tag: batch == item.id }" @click="setTag('batch', item.id)">{{ item.name }}</span>
                </div>
              </div>
      </div>
    </div>
    </section>
  </div>
  </div>

  <div style="background-color: #fff">
    <div class="max_width min_width">
      <section class="bgfff">

        <div class="flex " style="align-items: center; padding: 30px 0px; text-align: center;border-bottom: 1px solid #e8e8e8 ;">
          <div class="size_20 weight " style="color: #333333;width: 600px "> 企业名称</div>
          <div class="size_20 weight " style="color: #333333;width: 300px "> 地区</div>
          <div class="size_20 weight " style="color: #333333;width: 400px "> 行业门类</div>
          <div class="size_20 weight " style="color: #333333;width: 400px "> 行业大类</div>
          <div class="size_20 weight " style="color: #333333;width: 200px "> 批次</div>
        </div>
        <div style="background-color: #fff; border-bottom: 1px solid #e8e8e8 ;text-align: center;"  v-for="item in selectorder" :key="item.id">
          <div class="flex " style="align-items: center; padding: 30px 0px;">
            
            <div class="size_16 " style="color: #333333;;width: 600px "
              v-if="item.enterprise_name.length > 10">
              {{ item.enterprise_name }}
            </div>
            <div class="size_16 " style="color: #333333;width: 600px " v-else>
              {{ item.enterprise_name }}
            </div>
            <div class="size_16 " style="color: #333333;width: 300px ">
              {{ item.area_name }}
            </div>
            <div class="size_16 " style="color: #333333;width: 400px ">
              {{ item.server_trade_name }}
            </div>
            <div class="size_16 " style="color: #333333;width: 400px "
              v-if="item.trade_name.length > 10">
              {{ item.trade_name }}
            </div>
            <div class="size_16 " style="color: #333333;width: 400px " v-else>
              {{ item.trade_name }}
            </div>
            <div class="size_16 " style="color: #333333;width: 200px ">
              {{ item.batch }}
            </div>
            

           
          </div>
        </div>
      </section>
    </div>
  </div>
  <div>
    <!-- 分页 -->
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page="paginations.page_index" :page-sizes="paginations.page_sizes" :page-size="paginations.page_size"
      :layout="paginations.layout" :total="paginations.total">
    </el-pagination>
  </div>
  <app-footer></app-footer>
</div></template>

<script>
import Header from "@/components/Header/header.vue";
import Footer from "@/components/Footer/footer.vue";
export default {
  data() {
    return {
      offsetHeight: 0,
      keyword: "",
      num: "",
      allselectorder: [],
      selectorder: "",
      releasetext: "",
      total: "",
      address: '',
      batcharr:[],
      batch:'',
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      options: [],
      type:''
    };
  },

  components: {
    "app-header": Header,
    "app-footer": Footer,
  },
  created() {
    this.type =this.$route.query.type
    this.getcity()
    this.getbatch()
    this.batch=''
    this.address = ''
      this.marketlist();
   
   
    // console.log(this.pattern[0].name, "pattern[0].name");
    // this.releasetext = this.pattern[0].name;
  },
  methods: {
    getcity() {
      let data = {
        token: localStorage.eleToken,
      };
      this.$get("area_select", data).then((res) => {
        console.log(res, "re789s");
        this.options = res.result
      });
    },
    getbatch() {
      let data = {
        type: this.type  
      };
      this.$get("batch_list", data).then((res) => {
        console.log(res, "re789s");
        this.batcharr = res.result
      });
    },
    setTag(name, id = "") {
      console.log(name, 'name')
      console.log(id, 'id')
      this[name] = id;
      console.log("789");
      this.marketlist()
    },

    // 获取专精特新列表
    marketlist(item) {
      let data = {
        page: item ? item : this.paginations.page_index,
        limit: this.paginations.page_size,
        city_id: this.address,
        batch:this.batch,
        type:this.type  
      };
      console.log(data, "data");
      this.$post("enterprise_list", data).then((res) => {
        console.log(res.result, "res.result");
        this.allselectorder = res.result;
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          console.log("111");
          this.paginations.total = res.count;
        }

        //设置默认的分页数据
        if (this.allselectorder.length != 0) {
          this.selectorder = this.allselectorder.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.selectorder = [];
        }
        console.log(this.allselectorder, "this.allselectorder");
      });
    },

    getHeight(data) {
      console.log(data, "data");
      this.offsetHeight = data;
    },

    // 分页
    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;

      this.marketlist();

      this.selectorder = this.allselectorder.filter((item, index) => {
        return index < page_size;
      });
    },

    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      this.marketlist(page);
      //容器
      let tables = [];
      for (let i = index; i < nums; i++) {
        if (this.allselectorder[i]) {
          tables.push(this.allselectorder[i]);
        }
        this.selectorder = tables;
      }
    },
  },
};
</script>
<style scoped>
.sear_search {
  border-radius: 6px;
  margin-left: 12px;
}

.el-input__inner {
  background-color: #f5f5f5 !important;
  border: none !important;
  border-radius: 25px !important;
}

.sear_search .el-icon-search {
  right: 10px;
  color: #ff7129 !important;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  top: 10px;
  position: relative;
}

.sear_search .el-input__clear {
  height: 100% !important;
}

.laws_tag {
  padding: 3px 12px;

  color: #ff7129;
  background: #ffffff;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
}

::v-deep.el-pagination {
  text-align: center;
  padding: 20px 0px;
  background-color: #fff;
}

.productbox {
  width: 272px;
  height: 294px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  margin: 0px 40px 40px 0px;
}

.productbox:hover {
  box-shadow: 0px 2px 8px 0px rgba(0, 132, 255, 0.15);
}

.servehove:hover {
  color: #ff7129;
}

.wid_4 {
  width: 400px;
}
</style>
